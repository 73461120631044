<template>
<div>
  <Header></Header>
  <BGImg></BGImg>
  <div style="max-width: 1000px;margin: auto">
    <div class="title">{{video.title}}</div>
<!--    <div class="articleAuthor">-->
<!--      来源：中国安能集团  &nbsp;&nbsp; |  2021-11-16-->
<!--    </div>-->
    <div v-if="config.url != ''" style="width: 90%;margin: 30px auto">
      <vueMiniPlayer ref="vueMiniPlayer" :video="config" :mutex="true" />
    </div>
  </div>
  <Footer></Footer>
</div>
</template>

<script>
import Header from "@/components/Header";
import BGImg from "@/components/BGImg";
import Footer from "@/components/Footer";
export default {
  name: "Video",
  components: {Footer, BGImg, Header},
  data () {
    return {
      config: {
        // 视频播放源
        url: "",
        // 视频海报
        // cover: 'https://i.loli.net/2019/06/06/5cf8c5d9c57b510947.png',
        muted: false, // 是否静音播放
        loop: false, // 视频是否循环播放
        preload: 'auto', // 视频预加载
        poster: '', // 原生视频默认海报暂不设置
        volume: 1, // 默认音量
        autoplay: false, // 视频自动播放
      },
      video:{
        id:1,
        title:'',
        weight:0,
        cover:'',
        video:''
      }
    }
  },
  mounted() {
    // todo 改成用户查看

    this.axios.get('/api/video/user/view/'+this.$route.query.id).then(res=>{
      console.log(res)
      this.video = res.data;
      this.config.url = this.video.video;
    })
  }

}
</script>

<style scoped>
.title {
  font-size: 28px;
  padding: 15px 0;
  text-align: center;
  font-weight: normal;
  color: #065aa9;
  line-height: 1.6;
  margin-top: 30px;
}

.articleAuthor {
  text-align: center;
  padding: 12px 0;

  background-color: #f5f5f5;
  margin: 5px 0 15px 0;
}
</style>